import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import styled from "@emotion/styled";
import { ThemeProvider } from "emotion-theming";

import { theme } from "../../utils/global-styles/theme";
import AcumenAcademyScalableLogo from "./AcumenAcademyLogo/AcumenAcademyScalableLogo.js";
import AcumenAcademyUnScalableLogo from "./AcumenAcademyLogo/AcumenAcademyUnScalableLogo";
import BurgerMenuConductor from "./BurgerMenuConductor";
import DropdownLink from "./DropdownLink";
import NotficationBadge from "./Icons/NotificationBadge";
import SearchIcon from "./Icons/SearchIcon.js";
import newLogoGreen from "./newLogoGreen.png";
import newLogoPurple from "./newLogoPurple.png";
import newLogoOrange from "./newLogoOrange.png";
import newLogoBlue from "./newLogoBlue.png";
import DesktopArrow from "../Header/Dropdowns/DesktopArrow.js";
import { isUserType } from "../../utils/services/methods";
import AnimatedDropdown from "../AnimatedDropdown/AnimatedDropdown.js";

const Header = ({
  isLoggedIn,
  headerLinks,
  footerLinks,
  notificationLinks,
  currentPath,
  containerRef,
  animatedPartOfLogoRef,
}) => {
  const isHomePage = currentPath === "/";
  const Header = styled.header`
    width: 100%;
    position: fixed;
    z-index: 7;
    align-items: center;
    background-color: #f7f2ea;
    top: 0;
    display: flex;
    border-bottom-width: ${isHomePage ? "0" : "7px"};
    border-bottom-style: ${isHomePage ? "none" : "solid"};
    padding-top: 25px;

    @media only screen and (max-width: ${theme.breakpoints.tabletLandscapeUp}) {
      padding-top: 25px;
      padding-bottom: 10px;
    }
  `;
  const LogoContainer = styled(Link)`
    display: flex;
    align-items: center;
    padding-left: 40px;
    @media only screen and (min-width: ${theme.breakpoints.mobile}) {
      padding-left: 80px;
    }
  `;

  const LinksContainer = styled.nav`
    display: block;
    position: absolute;
    right: 40px;
    font-size: 18px;
    font-weight: normal;
    line-height: 13px;
    font-family: ${theme.fontFaces.headers};
    color: ${theme.colors.main};
    top: ${isLoggedIn ? "40.5px" : "25px"};

    a {
      &.active-page {
        font-weight: bold;
      }
    }
    @media only screen and (max-width: ${theme.breakpoints.mobile}) {
      gap: 1em;
    }
    @media only screen and (min-width: ${
      theme.breakpoints.tabletUp
    }) and (max-width: ${theme.breakpoints.tabletLandscapeUp}) {
      gap: 2em;
    }
    @@media only screen and (min-width: ${
      theme.breakpoints.tabletLandscapeUp
    }) and (max-width: ${theme.breakpoints.iPadPro}) {
    gap: 2em;
    
  }
  `;

  const NavLink = styled(Link)`
    padding: 10px;
    border-bottom-width: 1px;
    line-height: 17px;

    &:hover {
      color: black;
      background-color: ${theme.colors.secondary};
    }
  `;

  const InnerLinksContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: nowrap;
    align-items: center;
  `;

  const HamburgerMenuDisplay = styled.div`
    display: none;

    @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
      display: block;
    }
  `;

  const DesktopLinks = styled.div`
    display: none;

    @media only screen and (min-width: ${theme.breakpoints.iPadPro}) {
      display: flex;
      align-items: baseline;
    }
  `;

  const MyDashboardContainer = styled.div`
    font-size: 17px;
    margin-left: 40px;
    height: 40px;
    overflow: visible;
    cursor: pointer;
    position: relative;
  `;
  const MyDashboardHeader = styled.div`
    display: flex;
    cursor: pointer;
    align-items: center;

    &:hover .desktop-arrow {
      transform: rotate(180deg);
      transition: transform 0.1s ease;
    }
  `;
  const MyDashboardLinksContainer = styled.div`
    display: flex;
    position: absolute;
    flex-direction: column;
    padding-top: 10px;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
    &:hover .dashboard-links-container {
      opacity: 1;
    }
  `;
  const DashboardLink = styled(Link)`
    font-size: 17px;
    margin-left: 40px;
    cursor: pointer;
  `;
  const LinkTitle = styled.div`
    margin-right: 10px;
    cursor: pointer;
    font-size: 17px;
    margin-left: 40px;
    font-family: ${theme.fontFaces.paragraphs};
  `;

  const handleResize = () => {
    setMobileView(window.innerWidth < 1201);
  };

  const [isMobileView, setMobileView] = useState(false);
  useEffect(() => {
    // If executed server-side (no window object) it will return false(exit) out of the useEffect() hook
    if (typeof window !== "object") {
      return false;
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });
  const logoToShow = () => {
    const { themeName } = theme.colors;
    if (themeName === "purple") {
      return newLogoPurple;
    } else if (themeName === "green") {
      return newLogoGreen;
    } else if (themeName === "blue") {
      return newLogoBlue;
    } else {
      return newLogoOrange;
    }
  };
  const [showScalableLogo, setShowScalableLogo] = useState(false);
  // To ask Tony about this. C.Oduk 07/05/2023
  const [headerLinksExpanded, setHeaderLinksExpanded] = useState({});
  const [dashboardLinksExpanded, setDashboardLinksExpanded] = useState({});

  const toggleHeaderLinks = linkIndex => {
    setHeaderLinksExpanded(prevLinksExpanded => ({
      ...prevLinksExpanded,
      [linkIndex]: !prevLinksExpanded[linkIndex],
    }));
  };

  const toggleDashboardLinks = linkIndex => {
    setDashboardLinksExpanded(prevLinksExpanded => ({
      ...prevLinksExpanded,
      [linkIndex]: !prevLinksExpanded[linkIndex],
    }));
  };

  useEffect(() => {
    const currentPath = window?.location?.pathname;
    const isIndexPage = currentPath === "/";

    if (isIndexPage) {
      setShowScalableLogo(true);
    } else {
      setShowScalableLogo(false);
    }
  }, []);

  const headerLinksToDisplay = isLoggedIn
    ? headerLinks?.headerLinks?.slice(0, -2)
    : headerLinks?.headerLinks;

  const isFellow = isUserType("Fellow");
  const isFoundry = isUserType("Foundry");

  if (isLoggedIn) {
    headerLinks.myDashboardLinks = headerLinks.myDashboardLinks.filter(link => {
      if (link.showSubLinks) {
        link.subLinks = link.subLinks.filter(subLink => {
          if (
            subLink.linkText.toLowerCase().trim() === "fellowship" &&
            !isFellow
          ) {
            return false;
          }
          if (
            subLink.url.toLowerCase().trim() === "/dashboard?foundry" &&
            !isFoundry
          ) {
            return false;
          }
          if (
            subLink.url.toLowerCase().trim() === "/dashboard?community" &&
            isFellow
          ) {
            return false;
          }
          return true;
        });
      }
      return true;
    });
  }

  return (
    <ThemeProvider theme={{ ...theme }}>
      <Header className={isHomePage ? "" : "primary-border-dkBlue"}>
        <LogoContainer to="/" aria-label="logo">
          {showScalableLogo ? (
            <AcumenAcademyScalableLogo
              containerRef={containerRef}
              animatedPartOfLogoRef={animatedPartOfLogoRef}
            />
          ) : (
            <AcumenAcademyUnScalableLogo />
          )}
        </LogoContainer>
        <LinksContainer>
          <InnerLinksContainer>
            <DesktopLinks>
              {headerLinksToDisplay?.map((link, index) => {
                return (
                  <MyDashboardContainer key={index}>
                    <MyDashboardHeader className="border-bottom-light">
                      {link?.showSubLinks ? (
                        <AnimatedDropdown
                          title={link.title}
                          items={link.subLinks}
                        />
                      ) : (
                        <DashboardLink
                          to={link?.url}
                          className={`header-links-font ${
                            link?.url === "/register"
                              ? "black-button"
                              : "dashboard-main-link"
                          }`}
                        >
                          {link.title}
                        </DashboardLink>
                      )}
                    </MyDashboardHeader>
                  </MyDashboardContainer>
                );
              })}
              {isLoggedIn && (
                <>
                  {headerLinks?.myDashboardLinks?.map((link, index) => {
                    return (
                      <MyDashboardContainer key={index}>
                        <MyDashboardHeader className="border-bottom-light">
                          {link?.showSubLinks ? (
                            <AnimatedDropdown
                              title={link.title}
                              items={link.subLinks}
                            />
                          ) : (
                            <DashboardLink
                              to={link?.url}
                              className="header-links-font"
                            >
                              {link.title}
                            </DashboardLink>
                          )}
                        </MyDashboardHeader>
                      </MyDashboardContainer>
                    );
                  })}
                </>
              )}
            </DesktopLinks>
            <HamburgerMenuDisplay>
              <BurgerMenuConductor
                isLoggedIn={isLoggedIn}
                headerLinks={headerLinks}
                footerLinks={footerLinks}
                setShowScalableLogo={setShowScalableLogo}
                showScalableLogo={showScalableLogo}
              />
            </HamburgerMenuDisplay>
            {isLoggedIn && (
              <NotficationBadge notificationLinks={notificationLinks} />
            )}
            {/* <SearchIcon /> */}
          </InnerLinksContainer>
        </LinksContainer>
      </Header>
    </ThemeProvider>
  );
};

Header.propTypes = {
  isLoggedIn: PropTypes.bool,
  headerLinks: PropTypes.shape({
    loggedInLinks: PropTypes.arrayOf(
      PropTypes.shape({
        linkText: PropTypes.string,
        url: PropTypes.string,
      })
    ),
    loggedOutLinks: PropTypes.arrayOf(
      PropTypes.shape({
        linkText: PropTypes.string,
        url: PropTypes.string,
      })
    ),
    myDashboardLinks: PropTypes.arrayOf(
      PropTypes.shape({
        linkText: PropTypes.string,
        url: PropTypes.string,
      })
    ),
  }),

  footerLinks: PropTypes.shape({
    newsletterTitle: PropTypes.string,
    newsletterDescription: PropTypes.string,
    support: PropTypes.shape({
      title: PropTypes.string,
      linksCollection: PropTypes.shape({
        items: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.string,
          })
        ),
      }),
    }),
    about: PropTypes.shape({
      title: PropTypes.string,
      linksCollection: PropTypes.shape({
        items: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.string,
          })
        ),
      }),
    }),
    help: PropTypes.shape({
      title: PropTypes.string,
      linksCollection: PropTypes.shape({
        items: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.string,
          })
        ),
      }),
    }),
    termsOfUse: PropTypes.objectOf(PropTypes.string),
    privacyPolicy: PropTypes.objectOf(PropTypes.string),
    facebook: PropTypes.objectOf(PropTypes.string),
    twitter: PropTypes.objectOf(PropTypes.string),
    instagram: PropTypes.objectOf(PropTypes.string),
  }),
  notificationLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      datePublished: PropTypes.string,
      url: PropTypes.string,
      viewDateTimeStamp: PropTypes.string,
    })
  ),
  currentPath: PropTypes.string,
};

export default Header;
